import React, { useState } from "react";
import Select from "react-select";
import { BsEyeSlashFill, BsEyeFill } from "react-icons/bs";
import { useField } from "formik";
import { HiCheck } from "react-icons/hi";

interface CustomInputProps {
	name: string;
	type?:
		| "select"
		| "checkbox"
		| "number"
		| "email"
		| "textarea"
		| "text"
		| "password";
	options?: Option[];
	checked?: boolean;

	id: string;
	additionalClasses?: string;
	placeholder?: string;
	placeholderStyleOptions?: { [index: string]: boolean | string | number };
	label?: string | React.ReactNode;
	floatingLabel?: boolean;
	labelStyles?: string;
	pattern?: string;
	password?: boolean;
	additionalAttributes?: { [propName: string]: any };
	select?: boolean;
	selectStyleOptions?: { [index: string]: boolean | string | number };
	disabled?: boolean;
	required: boolean;
	autoComplete?: string;
	selectValueKey?: string;
	containerStyle?: string;
	secondaryContainerStyle?: string;
	minLength?: number;
	maxLength?: number;
	// abel?: React.ReactNode;
}

interface Option {
	value: string;
	label: string;
}

export const FormikInput = ({
	id,
	label,
	floatingLabel,
	labelStyles,
	name,
	type = "text",
	placeholder,
	options,
	required,
	password,
	additionalClasses,
	selectStyleOptions,
	placeholderStyleOptions,
	disabled,
	selectValueKey,
	containerStyle,
	secondaryContainerStyle,
	minLength,
	maxLength,
	...props
}: CustomInputProps) => {
	const [visible, setVisible] = useState<boolean>(false);

	const [_, meta] = useField(name);
	const { value, onChange: handleOnChange } = useInputProps(
		type,
		name,
		selectValueKey
	);

	switch (type) {
		case "select":
			return (
				<div
					className={`relative flex w-full flex-col justify-center gap-y-1  ${
						containerStyle ? containerStyle : ""
					}`}
				>
					{!floatingLabel && (
						<label
							htmlFor={name}
							className={`relative text-left font-medium capitalize text-primary-700 md:font-semibold ${
								labelStyles ?? ""
							}`}
						>
							{label}

							{required ? (
								<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
									*
								</span>
							) : (
								""
							)}
						</label>
					)}
					<Select
						name={name}
						id={id}
						options={options}
						placeholder={placeholder}
						className={"font-light"}
						styles={customSelectStyles(
							selectStyleOptions,
							placeholderStyleOptions,
							Boolean(meta.touched && meta.error)
						)}
						defaultValue={
							value
								? options?.find(
										(option) => option.value === value
								  )
								: placeholder
						}
						value={
							value
								? options?.find(
										(option) => option.value === value
								  )
								: placeholder
						}
						onChange={handleOnChange}
						isDisabled={disabled}
						{...props}
					/>
					{meta.touched && meta.error ? (
						<p className="mt-2 text-xs text-error-600">
							{meta.error}
						</p>
					) : null}
					{floatingLabel && (
						<label
							htmlFor={name}
							className={`absolute -top-3 left-4  bg-white px-3 text-left font-medium capitalize text-primary-700 md:font-semibold ${labelStyles}`}
						>
							{label}

							{required ? (
								<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
									*
								</span>
							) : (
								""
							)}
						</label>
					)}
				</div>
			);
		case "checkbox":
			return (
				<div className={` ${containerStyle ? containerStyle : ""}`}>
					<div
						className={`flex w-full items-center gap-2 ${
							secondaryContainerStyle
								? secondaryContainerStyle
								: ""
						}`}
					>
						<input
							type="checkbox"
							checked={value}
							className={`peer relative h-4 w-4 shrink-0 cursor-pointer appearance-none rounded-[1px] border-[0.5px] ${
								meta.touched && meta.error
									? "border-error-600"
									: "border-primary-900"
							}  bg-transparent text-white checked:border-primary-800 checked:bg-primary-800 ${
								labelStyles ?? ""
							}`}
							{...props}
							minLength={minLength}
							maxLength={maxLength}
							onChange={handleOnChange}
							disabled={disabled}
							id={name}
						/>
						{label && (
							<label
								htmlFor={name}
								className={`cursor-pointer text-sm font-[500] text-secondary-400 ${
									labelStyles ? labelStyles : ""
								}`}
							>
								{label}
							</label>
						)}
						<HiCheck className="pointer-events-none absolute mx-auto hidden h-3.5 w-4 text-white peer-checked:block" />
					</div>
					{meta.touched && meta.error ? (
						<p className="mt-2 text-xs text-error-600">
							{meta.error}
						</p>
					) : null}
				</div>
			);
		case "number":
		case "email":
		case "text":
		case "password":
			return (
				<div
					className={`relative flex w-full flex-col justify-center gap-y-1  ${
						containerStyle ? containerStyle : ""
					}`}
				>
					{!floatingLabel && (
						<label
							htmlFor={name}
							className={`relative text-left font-medium capitalize text-primary-700 md:font-semibold ${
								labelStyles ?? ""
							}`}
						>
							{label}

							{required ? (
								<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
									*
								</span>
							) : (
								""
							)}
						</label>
					)}
					<div className="relative">
						<input
							type={!visible ? type : "text"}
							value={value}
							minLength={minLength}
							maxLength={maxLength}
							onChange={handleOnChange}
							placeholder={placeholder}
							disabled={disabled}
							className={`${
								additionalClasses
									? `releative peer h-14 w-full rounded-md border text-[#263238] ${
											meta.touched && meta.error
												? "border-error-600"
												: "border-secondary-300"
									  } px-7 py-2  text-[#333333]  outline-none placeholder:text-[14px] placeholder:text-secondary-300 autofill:bg-white  ${additionalClasses} `
									: ` releative peer h-14 w-full rounded-md border  ${
											meta.touched && meta.error
												? "border-error-600"
												: "border-secondary-300"
									  } bg-transparent px-7 py-2 text-[15px] text-[#333333] outline-none placeholder:text-[14px] placeholder:text-secondary-300 autofill:bg-white`
							}`}
							{...props}
						/>
						{password && (
							<i className="absolute right-4 top-1/2  flex -translate-y-1/2 items-center text-secondary-300">
								<button
									type="button"
									onClick={() => setVisible(!visible)}
									aria-label={
										visible
											? "Hide password"
											: "Show password"
									}
								>
									<span className="sr-only">
										{visible
											? "Hide password"
											: "Show password"}
									</span>
									{visible ? (
										<BsEyeSlashFill />
									) : (
										<BsEyeFill />
									)}
								</button>
							</i>
						)}
					</div>
					{meta.touched && meta.error ? (
						<p className="mt-2 text-xs text-error-600">
							{meta.error}
						</p>
					) : null}

					{floatingLabel && (
						<label
							htmlFor={name}
							className={`absolute -top-3 left-4  bg-white px-3 text-left font-medium capitalize text-primary-700 md:font-semibold ${labelStyles}`}
						>
							{label}

							{required ? (
								<span className="absolute top-1/2 ml-1 -translate-y-1/2 transform text-red-700">
									*
								</span>
							) : (
								""
							)}
						</label>
					)}
				</div>
			);
		// case 'textarea':
		//   return (
		//     <textarea value={value} onChange={handleOnChange} placeholder={placeholder} {...props} />
		//   );
		default:
			return null; // Handle unsupported type gracefully
	}
};

export const customSelectStyles = (
	selectStyleOptions?: any,
	placeholderStyleOptions?: any,
	isError?: boolean
) => ({
	control: (defaultStyles: any) => ({
		...defaultStyles,
		"&:hover": { borderColor: isError ? "#DB2719" : "#90A3BF" },
		height: "3.5rem",
		borderRadius: "0.5em",
		border: `1.4px solid ${isError ? "#DB2719" : "#90A3BF"}`,
		duration: "200",
		color: "white",
		backgroundColor: "transparent",
		boxShadow: `0 0 0 1px 'orange'`,
		outline: "none !important",
		fontSize: "1rem",
		paddingLeft: "17px",
		"@media (max-width: 500px)": {
			height: "3rem",
			fontSize: "15px",
		},
		...selectStyleOptions,
	}),
	placeholder: (base: any) => ({
		...base,
		display: "flex",
		alignItems: "center",
		fontWeight: 400,
		fontSize: "14px",
		color: "#90A3BF",
		"@media (max-width: 500px)": {
			height: "3rem",
			fontSize: "14px",
		},
		...placeholderStyleOptions,
	}),
	option: (provided: any, { isSelected, isFocused }) => ({
		...provided,
		color: isSelected ? "#fff" : "#333333",
		backgroundColor: isSelected
			? "#002060"
			: isFocused
			? "#F1F5F9"
			: undefined,
		cursor: "pointer",
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: "#333333",
	}),
});

export const useInputProps = (
	type = "text",
	name: string,
	selectValueKey?: string
): {
	value: any;
	onChange: (event: any) => void;
} => {
	const [field, , helpers] = useField(name);
	const { value } = field;

	const onChange = (eventOrValue: any) => {
		let newValue: any;

		// Handle different input types
		switch (type) {
			case "checkbox":
				newValue = eventOrValue.target.checked;
				break;
			case "select":
				newValue = selectValueKey
					? eventOrValue[selectValueKey]
					: eventOrValue.value;
				break;
			default:
				newValue = eventOrValue.target.value;
				break;
		}

		helpers.setValue(newValue);
	};

	return {
		value,
		onChange,
	};
};
